import {
  Box, Container, Flex,
  Text,
  Table, Thead, Tbody, Tr, Th, Td,
  Wrap,
  VStack,
  Grid,
  Spinner,
  useTheme,
} from '@chakra-ui/react';
import Chart from 'react-apexcharts';
import MainLayout from '@src/components/layouts/main';
import { TrafficLightColours as COLOURS, QUARTERS, TrafficLightColours } from '@src/modules/performance/shared/constants';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Pagination from '../../components/Pagination';
import ReportCard from '../../components/ReportCard';
import InfoContentCollapse from './components/information/InfoContentCollapse';
import InfoScroller from './components/information/InfoScroller';
import { mapValueToColourRange, roundKpiValue } from '../../shared/helpers';
import {
  useStateHighwaysPotholeRepairsHighVolumeKPI,
  useStateHighwaysPotholeRepairsKPI,
  useStateHighwaysPotholeRepairsRegionalKPI,
  useStateHighwaysPotholeRepairsNocData,
  useStateHighwaysPotholeRepairsHnoData,
  useStateHighwaysSubnetworks,
} from '../../context/reports-api-hooks';
import IndicatorLegend from '../../components/IndicatorLegend';
import { CircleIndicator } from '../../components/Indicators';
import LockYearBadge from '../../components/LockYearBadge';
import { selectedStateHighwaysSubnetwork } from '../../state';

const subheader = '% completed within 24 hours';

function StateHighwaysPotholeRepairs() {
  const { pathname } = useLocation();
  const reportUrl = pathname.split('/').pop();
  const theme = useTheme();
  const { data: subnetworks } = useStateHighwaysSubnetworks();
  const selectedSubnetwork = useRecoilValue(selectedStateHighwaysSubnetwork);
  const selectedSubnetworkName = selectedSubnetwork === '' ? 'All State Highways' : subnetworks?.find((subnetwork) => subnetwork.id === selectedSubnetwork)?.displayName;

  // KPI data
  const { data: stateHighwaysPotholeRepairsKPI, isLoading: isLoadingKpi } = useStateHighwaysPotholeRepairsKPI();
  const { data: highVolumeKpi, isLoading: highVolumeKpiIsLoading } = useStateHighwaysPotholeRepairsHighVolumeKPI();
  const { data: regionalKpi, isLoading: regionalKpiIsLoading } = useStateHighwaysPotholeRepairsRegionalKPI();
  // Chart data
  const { data: nocChartData, isLoading: nocChartDataIsLoading } = useStateHighwaysPotholeRepairsNocData();
  const { data: hnoChartData, isLoading: hnoChartDataIsLoading } = useStateHighwaysPotholeRepairsHnoData();

  const isLoading = isLoadingKpi || highVolumeKpiIsLoading || regionalKpiIsLoading || nocChartDataIsLoading || hnoChartDataIsLoading;

  // Get the colour for the KPI values
  const kpiColour = mapValueToColourRange(highVolumeKpi?.value, 'stateHighwaysPotholeRepairsKpi');
  const highVolumeColour = mapValueToColourRange(highVolumeKpi?.value, 'stateHighwaysPotholeRepairsHighVolume');
  const regionalColour = mapValueToColourRange(regionalKpi?.value, 'stateHighwaysPotholeRepairsRegional');

  // this controls the expansion of the help section
  const [index, setIndex] = useState(null);
  const helpSectionRef = useRef(null);
  const expandHelpSection = () => {
    setIndex(0);
  };

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Pagination currentReportUrl={reportUrl} />
        <ReportCard mt={2} pt={0}>
          <ReportCard.Header title="State Highways Pothole Repairs" subtitle={subheader} info={<InfoScroller expandHelpSection={expandHelpSection} ref={helpSectionRef} />}>
            {stateHighwaysPotholeRepairsKPI?.value !== null && !isLoading && (
              <Flex align="center" ml={4}>
                <Box mr={3}>
                  <Text
                    color={COLOURS[kpiColour]}
                    fontSize={36}
                    textAlign="center"
                  >
                    {roundKpiValue(stateHighwaysPotholeRepairsKPI?.value, 5, 1)}
                    %
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" color="gray" fontSize="sm">
                    % completed within 24 hours
                    <br />
                    <Text as="span" fontSize="xs" color="gray">
                      as at
                      {' '}
                      {QUARTERS.find((q) => q.id === parseInt(stateHighwaysPotholeRepairsKPI?.quarter, 10))?.label}
                      {' '}
                      {stateHighwaysPotholeRepairsKPI?.lockYear}
                    </Text>
                  </Text>
                </Box>
              </Flex>
            )}
          </ReportCard.Header>
          <ReportCard.Body>
            {isLoading && <Spinner />}
            {!isLoading && (
              <>
                <Text mb={5} textStyle="reportSectionHeader">
                  {selectedSubnetworkName}
                </Text>
                <Wrap spacing={4}>
                  <Box>
                    <Grid templateColumns="repeat(2, 280px)" gap={4}>
                      <VStack spacing={4} border="1px solid" borderColor="gray.100" p={4} borderRadius="md">
                        <Text mb={5} fontSize="sm" fontWeight="bold" textAlign="center">
                          High-volume, national
                          <br />
                          and arterial roads
                        </Text>
                        {!highVolumeKpiIsLoading && <LockYearBadge lockYear={highVolumeKpi?.lockYear} quarter={highVolumeKpi?.quarter} />}
                        <CircleIndicator
                          size={90}
                          colour={COLOURS[highVolumeColour]}
                          value={highVolumeKpi?.value !== null && highVolumeKpi?.value !== undefined ? `${roundKpiValue(highVolumeKpi?.value, 5, 1)}%` : null}
                          isLoading={highVolumeKpiIsLoading}
                          padding={0}
                        />
                        <Text
                          textAlign="center"
                          fontSize="xs"
                          whiteSpace="pre-line"
                          color="gray.600"
                        >
                          % completed within 24 hours
                        </Text>
                        <IndicatorLegend items={[
                          { label: '>= 95%', color: TrafficLightColours.GREEN },
                          { label: '< 95%', color: TrafficLightColours.RED },
                        ]}
                        />
                      </VStack>
                      <VStack spacing={4} border="1px solid" borderColor="gray.100" p={4} borderRadius="md">
                        <Text mb={5} fontSize="sm" fontWeight="bold" textAlign="center">
                          Regional, primary collector,
                          <br />
                          and secondary collector roads
                        </Text>
                        {!regionalKpiIsLoading && <LockYearBadge lockYear={regionalKpi?.lockYear} quarter={regionalKpi?.quarter} />}
                        <CircleIndicator
                          size={90}
                          colour={COLOURS[regionalColour]}
                          value={regionalKpi?.value !== null && regionalKpi?.value !== undefined ? `${roundKpiValue(regionalKpi?.value, 5, 1)}%` : null}
                          isLoading={regionalKpiIsLoading}
                          padding={0}
                        />
                        <Text
                          textAlign="center"
                          fontSize="xs"
                          whiteSpace="pre-line"
                          color="gray.600"
                        >
                          % completed within 24 hours
                        </Text>
                        <IndicatorLegend items={[
                          { label: '>= 85%', color: TrafficLightColours.GREEN },
                          { label: '< 85%', color: TrafficLightColours.RED },
                        ]}
                        />
                      </VStack>
                    </Grid>
                  </Box>
                </Wrap>
                <Box>
                  <Text my={5} fontWeight="bold">
                    Network Outcome Contracts (NOCs)
                  </Text>
                  <Chart
                    options={
                  {
                    title: {},
                    dataLabels: {
                      enabled: false,
                    },
                    legend: {
                      show: true,
                      position: 'top',
                    },
                    yaxis: [
                      {
                        title: {
                          text: '% completed within 24 hours',
                        },
                        labels: {
                          formatter(val) {
                            return `${val}%`;
                          },
                        },
                      },
                    ],
                    xaxis:
                      {
                        title: {},
                        labels: {
                          show: true,
                          rotate: -45,
                          hideOverlappingLabels: false,
                        },
                      },
                    colors: [theme.colors.brand.blue[700], theme.colors.brand.blue[300]],
                    tooltip: {
                      enabled: true,
                      shared: true,
                      intersect: false,
                      marker: {
                        show: false,
                      },
                      y: {
                        formatter(val) {
                          return `${val}%`;
                        },
                      },
                    },
                    plotOptions: {
                      bar: {
                        distributed: false,
                        horizontal: false,
                      },
                    },
                    annotations: {
                      yaxis: [
                        {
                          y: 90,
                          strokeDashArray: 8,
                          borderColor: theme.colors.gray[900],
                          label: {
                            borderColor: theme.colors.gray[900],
                            style: {
                              color: '#fff',
                              background: theme.colors.gray[900],
                            },
                            text: 'Target of 90% completed within 24 hours',
                          },
                        },
                      ],
                    },
                  }
                }
                    series={[{
                      name: 'Potholes repaired within 24 hours',
                      data: nocChartData?.map((item) => ({
                        x: item.name,
                        y: item.value,
                      })),
                    }]}
                    type="bar"
                    width="100%"
                    height="470"
                  />
                </Box>
                <Box>
                  <Text my={5} fontWeight="bold">
                    Regions
                  </Text>
                  <Chart
                    options={
                  {
                    title: {},
                    dataLabels: {
                      enabled: false,
                    },
                    legend: {
                      show: true,
                      position: 'top',
                    },
                    yaxis: [
                      {
                        title: {
                          text: '% completed within 24 hours',
                        },
                        labels: {
                          formatter(val) {
                            return `${val}%`;
                          },
                        },
                      },
                    ],
                    xaxis:
                      {
                        title: {},
                        labels: {
                          show: true,
                          rotate: -45,
                          hideOverlappingLabels: false,
                        },
                      },
                    colors: [theme.colors.brand.blue[700], theme.colors.brand.blue[300]],
                    tooltip: {
                      enabled: true,
                      shared: true,
                      intersect: false,
                      marker: {
                        show: false,
                      },
                      y: {
                        formatter(val) {
                          return `${val}%`;
                        },
                      },
                    },
                    plotOptions: {
                      bar: {
                        distributed: false,
                        horizontal: false,
                      },
                    },
                    annotations: {
                      yaxis: [
                        {
                          y: 90,
                          strokeDashArray: 8,
                          borderColor: theme.colors.gray[900],
                          label: {
                            borderColor: theme.colors.gray[900],
                            style: {
                              color: '#fff',
                              background: theme.colors.gray[900],
                            },
                            text: 'Target of 90% completed within 24 hours',
                          },
                        },
                      ],
                    },
                  }
                }
                    series={[{
                      name: 'Potholes repaired within 24 hours',
                      data: hnoChartData?.map((item) => ({
                        x: item.name,
                        y: item.value,
                      })),
                    }]}
                    type="bar"
                    width="100%"
                    height="470"
                  />
                </Box>
              </>
            )}
          </ReportCard.Body>
        </ReportCard>
        <InfoContentCollapse
          ref={helpSectionRef}
          title="Information Sources"
          html={null}
          index={index}
          setIndex={setIndex}
        >
          <Box background="white" p={4} borderRadius={4}>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th width="25%">REPORT ELEMENT</Th>
                  <Th>NOTES</Th>
                  <Th width="20%">SOURCE</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td colSpan={3}>
                    <Text fontWeight="bold" fontSize="md" color="brand.orange.500">State Highway Pothole Repairs</Text>
                  </Td>
                </Tr>
                <Tr verticalAlign="top">
                  <Td fontWeight="bold">% completed within 24 hours</Td>
                  <Td>
                    <Text mb={4}>
                      The indicator shows the colour grade for the percentage of potholes on all state highways that were repaired within 24 hours for the quarter:
                    </Text>
                    <IndicatorLegend items={[
                      { label: '>= 90%', color: TrafficLightColours.GREEN },
                      { label: '< 90%', color: TrafficLightColours.RED },
                    ]}
                    />
                  </Td>
                  <Td>Sourced from NZTA</Td>
                </Tr>
                <Tr>
                  <Td colSpan={3}>
                    <Text fontWeight="bold" fontSize="md" color="brand.orange.500">Targets for road categories</Text>
                  </Td>
                </Tr>
                <Tr verticalAlign="top">
                  <Td fontWeight="bold">
                    % completed within 24 hours for high-volume, national and arterial roads
                  </Td>
                  <Td>
                    <Text mb={4}>
                      The colour grade for the percentage of potholes on high-volume, national, and arterial state highways that were repaired within 24 hours for the quarter:
                    </Text>
                    <IndicatorLegend items={[
                      { label: '>= 95%', color: TrafficLightColours.GREEN },
                      { label: '< 95%', color: TrafficLightColours.RED },
                    ]}
                    />
                  </Td>
                  <Td>Sourced from NZTA</Td>
                </Tr>
                <Tr verticalAlign="top">
                  <Td fontWeight="bold">
                    % completed within 24 hours for regional, primary collector, and secondary collector roads
                  </Td>
                  <Td>
                    <Text mb={4}>
                      The colour grade for the percentage of potholes on regional, primary collector, and secondary collector state highways that were repaired within 24 hours for the quarter:
                    </Text>
                    <IndicatorLegend items={[
                      { label: '>= 85%', color: TrafficLightColours.GREEN },
                      { label: '< 85%', color: TrafficLightColours.RED },
                    ]}
                    />
                  </Td>
                  <Td>Sourced from NZTA</Td>
                </Tr>
                <Tr>
                  <Td colSpan={3}>
                    <Text fontWeight="bold" fontSize="md" color="brand.orange.500">Network Operating Contracts</Text>
                  </Td>
                </Tr>
                <Tr verticalAlign="top">
                  <Td fontWeight="bold">% completed within 24 hours</Td>
                  <Td>
                    <Text>
                      The quarterly percentages of potholes on state highways within each Network Operating Contract that were repaired within 24 hours.
                    </Text>
                  </Td>
                  <Td>Sourced from NZTA</Td>
                </Tr>
                <Tr>
                  <Td colSpan={3}>
                    <Text fontWeight="bold" fontSize="md" color="brand.orange.500">Regions</Text>
                  </Td>
                </Tr>
                <Tr verticalAlign="top">
                  <Td fontWeight="bold">% completed within 24 hours</Td>
                  <Td>
                    <Text fontSize="sm">
                      The quarterly percentages of potholes on state highways within each Region that were repaired within 24 hours.
                    </Text>
                  </Td>
                  <Td>Sourced from NZTA</Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </InfoContentCollapse>
      </Container>
    </MainLayout>
  );
}

export default StateHighwaysPotholeRepairs;
